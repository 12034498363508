import { ControllerParams } from '@wix/yoshi-flow-editor';

import type { IFeedItem } from 'api/feed/types';

import type { IViewModel } from 'controller/types';
import type { IRootStore } from 'store/types';
import { actions } from 'store/application';

import settingsParams from '../settingsParams';

type Resolvables = {
  persisted: boolean;
  feedItems: IFeedItem[];
};

export function resolve(
  vm: IViewModel,
  store: IRootStore,
  params: ControllerParams,
) {
  const { settings } = params.flowAPI;

  return async function (resolvables?: Resolvables) {
    if (resolvables?.persisted) {
      await vm._.comments.fetch(resolvables.feedItems);
      return resolvables;
    }

    const [groupId] = await Promise.all([
      vm.group$
        .fetch(settings.get(settingsParams.groupId))
        .unwrap()
        .then(({ group }) => group.id),
      vm.application$.fetchUserProfile(),
    ]);

    store.dispatch(actions.setAppData({ groupId }));

    const { items } = await vm.feed$.fetch(groupId as string, {
      limit: settings.get(settingsParams.postsCount),
    });

    return {
      persisted: true,
      feedItems: items,
    };
  };
}
